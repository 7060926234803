<template>
    <el-dialog
        title="新增or修改"
        :visible="dialogVisible"
        :modal="false"
        width="40%"
        :before-close="handleClose">
        <el-form ref="form" label-width="100px" :rules="rules" :model="formData">
            <el-form-item label="账号" prop="account">
                <el-input v-model="formData.account"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="formData.password"></el-input>
            </el-form-item>
            <el-form-item label="职位" prop="position">
                <el-input v-model="formData.position"></el-input>
            </el-form-item>
            <el-form-item label="账号状态">
                <el-switch v-model="status_flag"></el-switch>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile">
                <el-input v-model="formData.mobile"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="formData.remark" type="textarea"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="addOrEdit('form')">确定</el-button>
                <el-button @click="handleClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {addAPI, editAPI, detailAPI} from '../api'

export default {
    name: 'addOrEdit',
    data() {
        return {
            status_flag: true,
            formData: {
                id: 0,
                agent_id: 0,
                account: '',
                password: '',
                mobile: '',
                status: 20,
                position: '',
                remark: '',
            },
            rules: {
                account: [
                    {trigger: 'blur', message: '请输入账号', required: true}
                ],
                mobile: [
                    {trigger: 'blur', message: '请输入手机号码', required: true}
                ],
                position: [
                    {trigger: 'blur', message: '请输入职位', required: true}
                ],
            },
        }
    },

    props: {
        dialogVisible: {
            type: Boolean,
            default() {
                return 0
            },
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        }
    },

    watch: {
        id: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.getDetail(newVal)
                }
            }
        },
        status_flag: {
            immediate: true,
            handler(newVal) {
                this.formData.status = newVal ? 20 : 10
            }
        }
    },

    methods: {
        validateInteger(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入数字'));
            } else {
                const intValue = parseInt(value, 10);
                if (isNaN(intValue)) {
                    callback(new Error('请输入有效的整数'));
                } else if (intValue <= 0) {
                    callback(new Error('请输入大于0的整数'));
                } else {
                    callback();
                }
            }
        },

        handleClose() {
            this.$emit('close-form')
        },

        async getDetail(id) {
            this.formData = await detailAPI({id})
            this.status_flag = this.formData.status === 20
        },

        addOrEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        editAPI(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$emit('modify-success')
                        })
                    } else {
                        addAPI(this.formData).then(() => {
                            this.$message.success('新增成功')
                            this.$emit('modify-success')
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>


<style scoped lang="scss">

</style>
